import React from 'react'
import {
  Container,
  LogoFooter,
  SocialLinks,
  SocialItem,
  FooterTitle,
  FooterText,
  FooterSubtitle,
  FooterList,
  FooterListItem,
  FooterListTitle,
  FooterListText,
  SocialTitle,
  SocialList,
  ImageFooter,
} from 'components/Footer/styles'
import Link from 'next/link'
import { token } from 'styles/theme'
import { isMobile } from 'react-device-detect'

export const Footer = () => {
  return (
    <Container>
      <FooterTitle>
        <div>
          <LogoFooter>
            <ImageFooter src="/assets/logo-white.svg" alt="Juliana Nakashima Logo" width={94} height={93} />
          </LogoFooter>
        </div>
        <FooterText>
          Osteopatia <br /> e Quiropraxia
        </FooterText>
        <FooterSubtitle>CREFITO: 187359-F</FooterSubtitle>
      </FooterTitle>
      <div>
        <FooterList>
          <FooterListItem>
            <div>
              <ImageFooter src="/assets/icon-phone.svg" alt="telefone" width={24} height={24} />
            </div>
            <div>
              <FooterListTitle>Telefone</FooterListTitle>
              <FooterListText>11 92017-0738</FooterListText>
            </div>
          </FooterListItem>
          <FooterListItem>
            <div>
              <ImageFooter src="/assets/map-pin.svg" alt="endereço" width={24} height={24} />
            </div>
            <div>
              <FooterListTitle>Endereço</FooterListTitle>
              <FooterListText>
                Rua Palestina, 359<br /> Vila Mascote - São Paulo
              </FooterListText>
            </div>
          </FooterListItem>
          {/*<FooterListItem>
            <div>
              <ImageFooter src="/assets/icon-mail.svg" alt="email" width={24} height={24} />
            </div>
           
            <div>
              <FooterListTitle>Email</FooterListTitle>
              <FooterListText>
                <Link href="mailto:contato@juliananakashima.com.br">contato@juliananakashima.com.br</Link>
              </FooterListText>
            </div>
  </FooterListItem>*/}
          <FooterListItem>
            <div>
              <ImageFooter src="/assets/clock.svg" alt="horário de funcionamento" width={24} height={24} />
            </div>
            <div>
              <FooterListTitle>Horário de funcionamento</FooterListTitle>
              <FooterListText>
                Segunda a Sexta: 8h às 20h
              </FooterListText>
            </div>
          </FooterListItem>
        </FooterList>
      </div>
      <SocialLinks>
        <SocialTitle>Redes Sociais</SocialTitle>
        <SocialList>
          <SocialItem>
            <div>
              <a href="https://www.instagram.com/dra.juliana.nakashima" target="_blank" rel="noopener noreferrer">
                <ImageFooter src="/assets/icon-instagram.svg" alt="instagram" width={30} height={30} />
              </a>
            </div>
            {
              //somente destkop
              !isMobile && (
                <div>
                  <a href="https://www.instagram.com/dra.juliana.nakashima" target="_blank" rel="noopener noreferrer">
                    <FooterListTitle style={{ marginLeft: 20, color: `${token.color.white}` }}>
                      Instagram
                    </FooterListTitle>
                    <FooterListText style={{ marginLeft: 20, color: `${token.color.white}` }}>
                      /dra.juliana.nakashima
                    </FooterListText>
                  </a>
                </div>
              )
            }
          </SocialItem>
          <SocialItem>
            <div>
              <a href="https://www.facebook.com/dra.juliana.nakashima" target="_blank" rel="noopener noreferrer">
                <ImageFooter src="/assets/icon-facebook.svg" alt="facebook" width={30} height={30} />
              </a>
            </div>
            {
              //somente destkop
              !isMobile && (
                <div>
                  <a href="https://www.facebook.com/dra.juliana.nakashima" target="_blank" rel="noopener noreferrer">
                    <FooterListTitle style={{ marginLeft: 20, color: `${token.color.white}` }}>
                      Facebook
                    </FooterListTitle>
                    <FooterListText style={{ marginLeft: 20, color: `${token.color.white}` }}>
                      /dra.juliana.nakashima
                    </FooterListText>
                  </a>
                </div>
              )
            }
          </SocialItem>
        </SocialList>
      </SocialLinks>
    </Container>
  )
}
