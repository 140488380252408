import React from 'react'
import { TitleText } from 'components/Title/styles'
import PropTypes from 'prop-types'

export const Title = ({ family, children, color, size, width, mb, mt, pd, align, fw, id, lineHeight }) => {
  return (
    <TitleText
      color={color}
      size={size}
      width={width}
      mb={mb}
      mt={mt}
      pd={pd}
      align={align}
      fw={fw}
      id={id}
      family={family}
      lineHeight={lineHeight}
    >
      {children}
    </TitleText>
  )
}

Title.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  mb: PropTypes.string,
  mt: PropTypes.string,
  pd: PropTypes.string,
  align: PropTypes.string,
  fw: PropTypes.number,
  id: PropTypes.string,
  lineHeight: PropTypes.string,
  family: PropTypes.string,
}
