import React from 'react'
import { ButtonStyled } from 'components/Button/styles'
import PropTypes from 'prop-types'
import { token } from 'styles/theme'

export const Button = ({
  variant = 'transparent',
  outline,
  color = token.color.primary,
  fontSize,
  children,
  onClick,
  height,
  width,
  mb,
  mt,
  ml,
  mr,
  pd,
  fw,
}) => {
  return (
    <ButtonStyled
      variant={variant}
      onClick={onClick}
      outline={outline}
      color={color}
      fontSize={fontSize}
      width={width}
      height={height}
      mb={mb}
      mt={mt}
      ml={ml}
      mr={mr}
      pd={pd}
      fw={fw}
    >
      {children}
    </ButtonStyled>
  )
}

Button.propTypes = {
  variant: PropTypes.string,
  outline: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  mt: PropTypes.string,
  mb: PropTypes.string,
  ml: PropTypes.string,
  mr: PropTypes.string,
  pd: PropTypes.string,
  fw: PropTypes.string,
}
