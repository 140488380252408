import styled from 'styled-components'
import { token } from '../styles/theme'

export const Container = styled.section`
  @media (min-width: 992px) {
    padding: 0 calc((100% - 1280px) / 2);
  }
`

export const Heading = styled.div`
  padding: 0 16px;
  @media (min-width: 1200px) {
    padding: 0;
    position: absolute;
    top: 103px;
    z-index: 2;
  }
`

export const BackgroundImage = styled.div`
background: no-repeat url('/assets/home-background.jpeg');
width: 100%;
  height: 728px;
  background-size: contain;
  background-position: bottom;
  position: relative;
  @media (min-width: 500px) {
    height: 892px;
  }
  @media (min-width: 1200px) {
    height: 96vh;
    background-position: center 172px;
    background-size: contain;
  }
  @media (min-width: 1900px) {
    background-position: center 157px;
    background-size: contain;
  }
`

export const OsteoBackground = styled.div`
  background: no-repeat url('/assets/corpo.svg');
  width: 100%;
  background-size: cover;
  background-position: left;
  height: 858px;
  padding: 0 16px;
  @media (min-width: 992px) {
    padding: 0;
    height: 835px;
    background-size: contain;
    background-position: left 10px;
  }
  @media (min-width: 1900px) {
    height: 596px;
    background-position: -5px -174px;
    background-size: contain;
  }
`

export const IndicationContent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`

export const IndicationList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0 auto;
  display: block;

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    grid-row-gap: 20px;
  }
`

export const IndicationItem = styled.li`
  margin-bottom: 10px;
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
  &:before {
    content: url('/assets/list-item.png');
    width: 20px;
    height: 20px;
    position: relative;
    top: 4px;
    left: -12px;
  }
`

export const TreatmentContainer = styled.div`
  padding-bottom: 20px;
`

export const TreatmentContent = styled.div`
  padding: 16px 0;
`
export const TreatmentList = styled.ul`
  list-style: none;
  padding-left: 0;
  display: grid;
  align-content: center;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 0 16px;
  @media (min-width: 992px) {
    grid-template-columns: repeat(4, auto);
  }
  @media (min-width: 1900px) {
    padding: 0 calc((100% - 1280px) / 2);
    margin: 0;
  }
}
`
export const TreatmentItem = styled.li`
  background-color: ${token.color.primary};
  border-radius: 20px;
  width: 335px;
  height: 389px;
  padding: 60px 29px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  line-height: 22.5px;

  @media (min-width: 1900px) {
    max-width: 304px;
    max-height: 389px;
  }
`

export const IconTreatment = styled.img`
  width: 72px;
  height: 86px;
`

export const HowItWorks = styled.div`
  padding: 25px 0;
  background: no-repeat url('/assets/coluna.svg') ${token.color.lightSecondary};
  width: 100%;
  background-size: contain;
  background-position: 225px -208px;
  height: 1088px;
  margin-bottom: 58px;
  @media (min-width: 992px) {
    background-position: right;
    height: 620px;
    margin-bottom: 0;
  }
`

export const Interview = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    width: 60%;
    margin: 37.5px auto 44px;
  }
  @media (min-width: 1900px) {
    width: 45%;
  }
`
export const InterviewList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:last-child {
    margin-bottom: 25px;
  }
`
export const InterviewItem = styled.div`
  border-radius: 50px;
  width: 50px;
  height: 50px;
  background-color: ${token.color.primary};
  color: ${token.color.white};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const InterviewDescription = styled.div`
  background-color: ${token.color.white};
  border-radius: 50px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  width: 300px;
  height: auto;
  max-height: 181px;
  padding: 24px 36px 33px;
  margin-left: 14px;
  display: grid;
  align-content: center;

  @media (min-width: 992px) {
    width: 388.51px;
    height: 147px;
    right: 0;
    max-height: 181px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
  }
`
export const About = styled.div`
  display: block;
  padding: 0 16px;
  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
    padding: 80px 0 0;
  }
`

export const CompaniesListItem = styled.li`
  margin: 0 10px;

  p {
    padding: 0 !important;
  }
`
export const AboutContent = styled.div`
  position: relative;
`
export const JulianaImage = styled.div`
  position: relative;
  z-index: 1;
  left: 0;
  @media (min-width: 992px) {
    left: 84px;
  }
`
export const CompaniesList = styled.ul`
  list-style: none;
  padding-left: 0;
  align-items: center;
  display: flex;
  padding-top: 21px;
`
export const Graduation = styled.div`
  background-color: ${token.color.lightSecondary};
  border-radius: 50px;
  width: 92%;
  max-width: 815px;
  margin: 0 auto;

  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
    padding-top: 6px;
    width: 807px;
    max-width: 900px;
    position: absolute;
    bottom: 4px;
    left: -230px;
    margin: 0;
    height: 307px;
  }
`
export const GraduationList = styled.ul`
  height: auto;
  width: 100%;
  padding: 48px;
  line-height: 30.5px;
  @media (min-width: 992px) {
    padding: 22px 0 16px 248px;
  }
`
export const GraduationListItem = styled.li`
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
`

export const OsteSection = styled.div`
  position: relative;
  padding-top: 100px;
  height: 831px;
  display: grid;
  padding-left: 16px;

  @media (min-width: 992px) {
    display: block;
    padding-top: 100px;
    padding-left: 0;
    height: 738px;
  }
`
export const ContentOsteo = styled.div`
  position: relative;
  order: 1;
  margin-bottom: 55px;

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`
export const ImageForHome = styled.img``
