import styled from 'styled-components'
import { token } from 'styles/theme'

export const Container = styled.footer`
  background-color: ${token.color.primary};
  padding-bottom: 50px;
  padding-top: 30px;

  @media (min-width: 992px) {
    padding: 50px calc((100% - 1280px) / 2);
    display: flex;
    justify-content: space-between;
  }
`

export const FooterTitle = styled.div`
  text-align: center;
  color: ${token.color.white};
`

export const FooterText = styled.p`
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 21px;
  br {
    display: none;
  }

  @media (min-width: 992px) {
    text-align: left;
    margin-bottom: 58px;
    br {
      display: block;
    }
  }
`

export const FooterSubtitle = styled.label`
  font-weight: 400;
`

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media (min-width: 992px) {
    display: block;
    margin-top: 20px;
  }
`
export const SocialItem = styled.div`
  margin: 0 10px;

  @media (min-width: 992px) {
    display: flex;
    margin: 0 0 34px;
  }
`

export const FooterList = styled.ul`
  list-style: none;
  padding-left: 0;
  display: grid;
  justify-content: center;
  margin-top: 30px;

  @media (min-width: 992px) {
    margin-top: 30px;
    grid-template-columns: 346px 374px;
    grid-gap: 20px;
  }
`

export const LogoFooter = styled.div`
  margin: 24px auto;
  display: flex;
  justify-content: center;
  @media (min-width: 992px) {
    margin: 0;
    justify-content: left;
  }
`

export const FooterListItem = styled.li`
  display: grid;
  grid-template-columns: 46px 252px;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  color: ${token.color.white};
  @media (min-width: 992px) {
    margin: 10px 0;
  }
`

export const FooterListTitle = styled.p`
  margin-bottom: 5px;
  font-weight: 700;
  margin-top: 0;
  font-size: 15px;
`

export const FooterListText = styled.label`
  font-weight: 400;
  font-size: 15px;
`

export const SocialTitle = styled.p`
  display: none;

  @media (min-width: 992px) {
    display: block;
    font-weight: 700;
    font-size: 15px;
    color: ${token.color.white};
  }
`

export const SocialList = styled.div`
  display: flex;

  @media (min-width: 992px) {
    display: block;
  }
`
export const ImageFooter = styled.img``
