import styled from 'styled-components'

export const TitleText = styled.h1`
  font-size: ${props => `${props.size}px`};
  color: ${props => props.color};
  padding: ${props => `${props.pd}px`};
  margin-bottom: ${props => `${props.mb}px`};
  margin-top: ${props => `${props.mt}px`};
  width: 93%;
  text-align: center;
  font-family: ${props => (props.family ? props.family : 'Roboto, Open Sans, sans-serif')};
  font-weight: ${props => (props.fw ? props.fw : 400)};
  line-height: ${props => (props.lineHeight ? `${props.lineHeight}px` : 'normal')};
  @media (min-width: 1200px) {
    width: ${props => `${props.width}%`};
    text-align: ${props => (props.align ? props.align : 'left')};
  }
`
export const HeadingText = styled.h2`
  font-size: ${props => `${props.size}px`};
  color: ${props => props.color};
  padding: ${props => `${props.pd}px`};
  width: 100%;
  text-align: ${props => (props.align ? `${props.align}` : 'center')};
  font-weight: 500;
  margin-top: ${props => (props.mt ? `${props.mt}px` : '0')};
  margin-bottom: ${props => (props.mb ? `${props.mb}px` : '0')};
  line-height: ${props => (props.lineHeight ? `${props.lineHeight}px` : 'normal')};
  @media (min-width: 992px) {
    width: ${props => `${props.width}%`};
    text-align: left;
  }
`

export const HeadingText3 = styled.h3`
  font-size: ${props => `${props.size}px`};
  color: ${props => props.color};
  padding: 0 16px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  margin-top: ${props => (props.mt ? `${props.mt}px` : '0')};
  margin-bottom: ${props => (props.mb ? `${props.mb}px` : '0')};
  line-height: ${props => (props.lineHeight ? `${props.lineHeight}px` : 'normal')};
  @media (min-width: 992px) {
    width: ${props => `${props.width}%`};
    text-align: left;
  }
`

export const HeadingText4 = styled.h4`
  font-size: ${props => `${props.size}px`};
  color: ${props => props.color};
  padding: 0 16px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-family: ${props => (props.family ? props.family : 'Roboto, Open Sans, sans-serif')};
  margin-top: ${props => (props.mt ? `${props.mt}px` : '0')};
  margin-bottom: ${props => (props.mb ? `${props.mb}px` : '0')};
  line-height: ${props => (props.lineHeight ? `${props.lineHeight}px` : 'normal')};
  @media (min-width: 992px) {
    width: ${props => `${props.width}%`};
    text-align: left;
  }
`

export const HeadingText5 = styled.h5`
  font-size: ${props => `${props.size}px`};
  color: ${props => props.color};
  padding: 0 16px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-family: ${props => (props.family ? props.family : 'Roboto, Open Sans, sans-serif')};
  margin-top: ${props => (props.mt ? `${props.mt}px` : '0')};
  margin-bottom: ${props => (props.mb ? `${props.mb}px` : '0')};
  line-height: ${props => (props.lineHeight ? `${props.lineHeight}px` : 'normal')};
  @media (min-width: 992px) {
    width: ${props => `${props.width}%`};
    text-align: left;
  }
`

export const HeadingText6 = styled.h6`
  font-size: ${props => `${props.size}px`};
  color: ${props => props.color};
  padding: 0 16px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-family: ${props => (props.family ? props.family : 'Roboto, Open Sans, sans-serif')};
  margin-top: ${props => (props.mt ? `${props.mt}px` : '0')};
  margin-bottom: ${props => (props.mb ? `${props.mb}px` : '0')};
  line-height: ${props => (props.lineHeight ? `${props.lineHeight}px` : 'normal')};
  @media (min-width: 992px) {
    width: ${props => props.width};
    text-align: left;
  }
`
