import styled from 'styled-components'

export const ButtonStyled = styled.div`
  border-radius: 50px;
  height: ${props => (props.height ? `${props.height}px` : '53px')};
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  max-width: 275px;
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '20px')};
  margin-top: ${props => (props.mt ? `${props.mt}px` : '10px')};
  margin-bottom: ${props => (props.mb ? `${props.mb}px` : '10px')};
  margin-left: ${props => (props.ml ? `${props.ml}` : 'auto')};
  margin-right: ${props => (props.mr ? `${props.mr}` : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${props => (props.fw ? `${props.fw}` : '500')};
  line-height: 24px;
  cursor: pointer;
  padding: ${props => (props.pd ? `${props.pd}px` : '0px')};
  background-color: ${props => (props.variant ? props.variant : 'transparent')};
  color: ${props => props.color};
  border: ${props => (props.outline ? `1px solid ${props.outline}` : 'none')};
  @media (min-width: 992px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    margin-left: ${props => (props.ml ? `${props.ml}px` : '0px')};
    margin-right: ${props => (props.mr ? `${props.mr}px` : '0px')};
  }
`
